import React from "react"
import termsImg from "../assets//images/terms-of-service.jpg"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"

const TermsOfService = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="İptal ve İade"
        homePageText="Home"
        homePageUrl="/"
        activePageText="İptal ve İade"
      />
      <section className="terms-of-service-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg col-md">
              <div className="terms-of-service-content">
                <div className="privacy-policy-content">
                  <img src={termsImg} alt="pp" />

                  <p>
                    <h5>İade Şartları ve Uyuşmazlık Çözüm Politikası</h5>
                    Alışverişlio tarafından satışa sunulan hizmet ve ürünler ile
                    ilgili iade şartları aşağıda verilmiştir.
                    <br />
                    <h5 className="mt-2">Alışverişlio Satın Alım Hizmeti</h5>
                    Alışverişlio müşterisi, satın aldığı herhangi bir yazılım ve
                    altyapı hizmetini koşulsuz olarak iade edebilir. İade süresi
                    hizmetin başlangıç tarihinden itibaren 15 gündür.
                    <br />
                    <h5 className="mt-2">Lisans Yenileme Hizmeti</h5>
                    Lisans yenilemesi daha önce alınmış olan ve devam eden bir
                    hizmetin süre uzatımıdır. Yenilemelerde iade süresi 15
                    gündür.
                    <br />
                    <h5 className="mt-2">
                      Lisans Satın Alım ve Yenileme Hizmeti
                    </h5>
                    Alışverişlio müşterisi, satın aldığı herhangi bir yazılım
                    hizmeti ile ilgili 15 günden sonra iade yapamaz. Ancak
                    alisverislio.com dan kaynaklanan ve çözülemeyen sorunların
                    oluşması durumunda tüm hizmetlerde süre şartı olmaksızın
                    iade hakkı mevcuttur.
                    <br />
                    <h5 className="mt-2">İade Süreci</h5>
                    İadesi yapılan hizmetlerde alınan bedel eksiksiz olarak 7.
                    gün içinde iade edilir. Havale ve EFT ile yapılan ödemelerde
                    iade 7 gün içinde yine aynı yöntemle yapılır ve müşterinin
                    hesabına geçer. Kredi kartı ödemeleri 7 gün içinde yapılır
                    ancak müşteri kredi kartına bu ödemenin yansıması 7 günden
                    fazla sürebilir.
                    <br />
                    <h5 className="mt-2">Uyuşmazlık Çözüm Politikası</h5>
                    Tüm hizmetlerde müşteri kayıtları email adresi ile
                    tanımlanan kullanıcı adı altında tutulmaktadır. Müşteri
                    hizmeti ile ilgili, her türlü değişiklik ve benzeri
                    talepleri kayıtlı email adresinden kontrol panele giriş
                    yaparak veya email göndererek yaptırabilir. Email adresinin
                    başka bir kişinin eline geçmesi durumunda oluşabilecek
                    zararlardan alisverislio.com sorumlu tutulamaz. Alınan
                    hizmetin sahipliği konusunda birden fazla kişi veya kurum
                    arasında anlaşmazlık olması durumunda kontrol panel erişimi
                    geçici olarak kapatılır. Hizmetler ile ilgili ödeme yapan
                    kişi veya kuruma hizmet aktarılır. Diğer kişi veya
                    kurumların içeriklerin aktarılmasını onaylamadığı sürece
                    hizmetler boş olarak aktarılır.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default TermsOfService
